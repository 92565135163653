import mainlogo from "./../Assets/Images/400dpiLogoCropped.png"
import React from "react";
import { NavLink, Link } from "react-router-dom";

function Home() {
    return (
        <>
            <div className="container-fluid">
                <nav class="navbar navbar-expand-lg bg-body-tertiary">
                    <div class="container-fluid ni-navdiv content-container">
                        <a className="navbar-brand" href="/">
                            <img src={mainlogo} className="mainlogoimg" alt="nextIntake" height="90px" />
                        </a>
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse justify-content-end" id="navbarNavDropdown">
                            <ul class="navbar-nav">
                                <li className="nav-item">
                                    <NavLink exact className="nav-link" activeClassName="active" to='/' >Home</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" activeClassName="active" to="/about">About Us</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" activeClassName="active" to="/gallery">Gallery</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" activeClassName="active" to="/contact">Contact Us</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" activeClassName="active" to="/country">Country</NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </>
    );
}

export default Home;
