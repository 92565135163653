import ReactWhatsapp from "react-whatsapp";
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import mainlogo from "./../Assets/Images/nextintake top down logo.ico"

// import FloatingWhatsApp from "react-floating-whatsapp";
// import "react-floating-whatsapp/dist/index.css";

export default function Whatsapp() {
  return (
    <>
      <ReactWhatsapp number="+14375563535" message="Hello World!!!" />
      {/* <FloatingWhatsApp /> */}
      <FloatingWhatsApp phoneNumber="+14375563535" accountName="Next Intake" chatMessage="How can we help?" avatar={mainlogo} />
    </>
  );
}
