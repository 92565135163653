import React, { Component } from "react";
import Slider from "react-slick";


function ProcessStepCard() {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    // autoplay: false,
    // speed: 3000,
    // autoplaySpeed: 3000,
    // cssEase: "linear"
  };
  return (
    <section className="container step-wrapper">
      <div className="content-container">
        <div className="row">
          <div className="col-lg-3 col-md-3">
            <div class="box first-step-color">
              <div class="number circle first-step-color">1</div>
              <div class="content">
                <h4>RESEARCH</h4>
                <p>Enroll into the best college.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3">
            <div class="box second-step-color">
              <div class="number circle second-step-color">2</div>
              <div class="content">
              <h4>CONSULTATION</h4>
              <p>We will provide you all the tools and information.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3">
            <div class="box third-step-color">
              <div class="number circle third-step-color">3</div>
              <div class="content">
                <h4>SUBMISSION</h4>
                <p>Apply for visa with registered agent who knows what to do.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3">
            <div class="box forth-step-color">
              <div class="number circle forth-step-color">4</div>
              <div class="content">
                <h4>Approval</h4>
                <p>Extensive visa assistance with maximum chances of approval.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );

}

export default ProcessStepCard;