import React, { Component } from "react";
import Slider from "react-slick";


export default class SimpleSlider extends Component {
  render() {
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        speed: 3000,
        autoplaySpeed: 3000,
        cssEase: "linear"
    };
    return (
      <div className="center slider">
        <Slider {...settings}>
            <div>
            <img src="Images/college/seneca.png" />
            </div>
            <div>
            <img src="Images/college/stlawarance.png" />
            </div>
            <div>
                <img src="Images/college/10_acsenda.png" />
            </div>
            <div>
            <img src="Images/college/fleming.png" />
            </div>
            <div>
            <img src="Images/college/centennial.png" />
            </div>
            <div>
            <img src="Images/college/Humber.png" />
            </div>
            <div>
            <img src="Images/college/uow.png" />
            </div>
            <div>
            <img src="Images/college/laurentian.png" />
            </div>
            <div>
                <img src="Images/college/16_loyalist.png" />
            </div>
            <div>
                <img src="Images/college/20_university-of-essex.png" />
            </div>
            <div>
                <img src="Images/college/24_conestoga_college.jpg" />
            </div>
        </Slider>
      </div>
    );
  }
}