import React from 'react';

function OurService() {
  return (
    <div className="container mt-4">
      <section>
      <h2>Job Seekers</h2>
      <p>
        Jobs @ Ontario workforce is dedicated to helping job seekers find their dream careers. We provide a range of services tailored to your needs:
      </p>
      
      <ul className="list-group"> 
        <li className="list-group-item">Resume building: Our expert team will help you create a professional and impactful resume that highlights your skills and experience.</li>
        <li className="list-group-item">Job search assistance: We offer personalized job search support, including access to our extensive network of employers and job listings.</li>
        <li className="list-group-item">Career counseling: Our career counselors provide guidance and support to help you explore your career options, set goals, and make informed decisions.</li>
        <li className="list-group-item">Interview preparation: We offer interview coaching and preparation, equipping you with the skills and confidence to succeed in job interviews.</li>
        <li className="list-group-item">Skills development: Enhance your skills through our training programs and workshops, designed to improve your employability and stay competitive.</li>
      </ul>
    </section>
    
    <section>
      <h2>Employers</h2>
      <p>
        Jobs @ Ontario workforce partners with employers to streamline their talent acquisition process and find the perfect candidates for their organizations:
      </p>
      
      <ul className="list-group">
        <li className="list-group-item">Recruitment services: We utilize our extensive network and expertise to identify and attract top talent that matches your organization's needs.</li>
        <li className="list-group-item">Screening and selection: Our team conducts thorough candidate screening, ensuring that only qualified and suitable candidates are presented to you.</li>
        <li className="list-group-item">Customized solutions: We understand that each organization is unique. We tailor our services to align with your company's culture, values, and specific requirements.</li>
        <li className="list-group-item">Staffing solutions: Whether you need temporary, permanent, or contract-based staffing solutions, we can assist you in finding the right candidates efficiently.</li>
        <li className="list-group-item">Employer branding: We help you enhance your employer brand to attract top talent and create a positive reputation in the job market.</li>
      </ul>
    </section>
    </div>
  );
}

export default OurService;
