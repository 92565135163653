import React from 'react';
import SimpleSlider from '../sharedcomponent/SlickCarousel';
function About() {
  return (
    <>
      <div className="container-fluid">
        <div className="content-container center-flex mx-auto mt-4">
          <div className="col-lg-6 mr-auto">
            <div className="content">
              <h1>Journey towards your future education</h1>
              <p className="outMotto">Join us for your next study abroad journey. <br /> You will get all the help you need </p>

              <button className="btn btn-primary" type="button">Apply Now
                <img src="/assets/image/right-arrow.svg" alt="" /></button>
            </div>
          </div>
        </div>
      </div>
      <SimpleSlider />
      <div class="about-container content-container">
        <h1>About Us</h1>
        <p>At Next Intake, we are dedicated team of experienced consultants in the field of education. Collectively, we provide innovative and comprehensive services to empower students in achieving their academic goals. Our range of services is designed to assist you at every step of your educational journey, ensuring a seamless and successful transition. </p>
        <p>Next Intake started its operations in 2018 in Canada. We supported over 2000 international students for their college/University admissions. These students were taken care by our local team (Immigration Consultants, Lawyers, and Advisors) for their Canadian dreams. in Canada, USA, Europe, New Zealand and Australia.</p>
      </div>
    </>
  );
}

export default About;
