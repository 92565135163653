import React from "react";
import BottomLogo from "./../Assets/Images/whitecolrologo.png"
import Whatsapp from "./Whatsapp";

function Footer() {
  return (
    <>
     <footer class="w-100 py-4 flex-shrink-0">
        <div class="container py-4">
            <div class="row gy-4 gx-5">
                <div class="col-lg-4 col-md-6">
                <img src={BottomLogo} alt="Company Logo" className="footerlogo" /><br /><br />
                <div className="footertitle text-white">Write us at </div>
                <div className="text-white"> info@nextintake.ca</div> <br />
                <p class="small text-muted mb-3">&copy; Copyrights. All rights reserved.</p>
                <div className="footertitle text-white">Socially Connect with us</div><br />
                <div className="social-icons">
                  <a href="#" className="social-icon"><i className="fab fa-facebook-f"></i></a>
                  <a href="#" className="social-icon"><i className="fab fa-twitter"></i></a>
                  <a href="#" className="social-icon"><i className="fab fa-instagram"></i></a>
                  <a href="#" className="social-icon"><i className="fab fa-linkedin-in"></i></a>
                </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <h5 class="text-white mb-3">Legal</h5>
                    <ul class="list-unstyled text-muted">
                  <li><a href="#" className="footerfont">Privacy Policy</a></li>
                  <li><a href="#" className="footerfont">Terms and Conditions</a></li>
                  <li><a href="#" className="footerfont">Terms of Use </a></li>
                  <li><a href="#" className="footerfont">Refund Policy </a></li>
                </ul>
                </div>
                <div class="col-lg-4 col-md-6">
                    <ul class="list-unstyled text-muted">
                    <h5 class="text-white mb-3">Quick links</h5>
                    <li><a href="#" className="footerfont">Home</a></li>
                  <li><a href="#" className="footerfont">About Us</a></li>
                  <li><a href="#" className="footerfont">Gallery</a></li>
                  <li><a href="#" className="footerfont">contact Us</a></li>
                </ul>
                </div>
            </div>
        </div>
    </footer>
    <Whatsapp />
    </>
  );
}

export default Footer;
