import React from 'react';
import SimpleSlider from '../sharedcomponent/SlickCarousel';
import ProcessStepCard from './ProcessStepCard';

function Home() {
  return (
    <>
      <main>
        <div className="container-fluid bg-image main-image">
          <div className="content-container herodiv">
            <div className="col-lg-6 mr-auto">
              <div className="content">
                <h1>Embark on a Journey of Education, Exploration, and Transformation through Study Abroad!</h1>
                <p className="outMotto">Join us for your next study abroad journey. <br /> You will get all the help you need </p>

                <button className="btn btn-primary" type="button">Apply Now
                  <img src="/assets/image/right-arrow.svg" alt="" /></button>
              </div>
            </div>
          </div>
        </div>
        <SimpleSlider />

        <ProcessStepCard />



        <div className="container cutting-edge-css">
            <h1>Our Services</h1>
          <br />




          <div className="row">
            <div className="col-lg-4 col-md-4">
              <div className="card">
                <img src="Images/recruitment.png" className="card-img-top" alt="..." />
                <div className="card-body">
                  <h5 className="card-title text-uppercase">Registered immigration consultant</h5>
                  <p className="card-text">Your Trusted Guide for Successful Immigration: Registered Immigration Consultant Assisting Your Journey.</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4">
              <div className="card">
                <img src="Images/student.png" className="card-img-top" alt="..." />
                <div className="card-body">
                  <h5 className="card-title text-uppercase">Canadian lawyer</h5>
                  <p className="card-text">Empowering Your Rights and Protecting Your Interests - Experienced Lawyers at Your Service.</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4">
              <div className="card">
                <img src="Images/partner.png" className="card-img-top" alt="..." />
                <div className="card-body">
                  <h5 className="card-title text-uppercase">College and universities</h5>
                  <p className="card-text">Discover Endless Opportunities for Growth and Success at Premier Colleges and Universities.</p>
                </div>
              </div>
            </div>

          </div>
          <br />
        </div>

        <div className="container">

          <img className="img-fluid" src="Images/map.png" alt="Blue Map" />

        </div>

      </main>
    </>
  );
}

export default Home;
