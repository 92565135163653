import React from 'react';
import SimpleSlider from '../sharedcomponent/SlickCarousel';
function Country() {
  return (
    <>
      <div className="container-fluid">
        <div className="content-container center-flex mx-auto mt-4">
          <div className="col-lg-6 mr-auto">
            <div className="content">
              <h1>Journey towards your future education</h1>
              <p className="outMotto">Join us for your next study abroad journey. <br /> You will get all the help you need </p>

              <button className="btn btn-primary" type="button">Apply Now
                <img src="/assets/image/right-arrow.svg" alt="" /></button>
            </div>
          </div>
        </div>
      </div>
      <div className="container">

<img className="img-fluid" src="Images/map.png" alt="Blue Map" />

</div>
    </>
  );
}

export default Country;
